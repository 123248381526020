<template>
  <div>
    <el-card class="box-card" shadow="never" style="margin-top: 10px">
      <TableList :tableData="tableData" :tablePagination="tablePagination" @getTableData="getAllData"
                 @activeName="activeName" :isActive="isActive" @rowDbclick="rowDbclick"
                 :categoryType="'normal'" :type="'my'"/>
    </el-card>
    <el-dialog
        :visible.sync="dialogVisible"
        fullscreen
    >
      <product-details @handleButton="handleButton" :previewData="previewData"
                       :loading="loading" @close="dialogVisible=false"></product-details>
    </el-dialog>
    <el-dialog
        :visible.sync="brandVisible"
        fullscreen
    >
      <brand-details @handleButton="handleButton" :previewData="previewData"
                     :loading="loading" @close="dialogVisible=false"></brand-details>
    </el-dialog>

  </div>
</template>

<script>
import TableList from './components/tableList'
import { mapGetters } from 'vuex'
import ProductDetails from '@/pages/inquiries/productDetails.vue'
import brandDetails from '@/pages/inquiries/brandDetails.vue'


export default {
  name: 'index',
  components: { ProductDetails, TableList, brandDetails },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      tableData: [],
      drawerVisible: false,
      rowId: 'add',
      tablePagination: {
        total: 0,
        page_size: 15,
        offset: 0,
        current_page: 1
      },
      isActive: 'my',
      dialogVisible: false,
      previewPagination: {},
      previewData: {},
      loading: false,
      brandVisible: false
    }
  },
  methods: {
    handleButton(type) {
      let current_page = this.previewPagination.current_page
      this.loading = true
      if (type === 'preBtn') {
        if (current_page != 1)
          this.previewPagination.current_page = current_page - 1
        else {
          this.previewPagination.current_page = 1
        }
      } else {
        if (this.previewPagination.current_page !== this.previewPagination.total) {
          this.previewPagination.current_page = this.previewPagination.current_page + 1
        }
      }
      this.getAllData(this.previewPagination, 'preview')
    },
    async rowDbclick(index) {
      this.previewPagination = JSON.parse(JSON.stringify(this.tablePagination))
      this.previewPagination.page_size = 1
      this.previewPagination.current_page = index + 1
      await this.getAllData(this.previewPagination, 'preview')
    },
    activeName(val) {
      this.isActive = val
      this.getAllData()
    },
    async getAllData(val, type) {
      let searchCondition
      if (!val) {
        searchCondition = {
          page_size: 15,
          current_page: 1
        }
      } else searchCondition = val
      //普通询单=1 全案询单=2
      searchCondition.type = 1
      let list, page_info, data
      if (this.isActive === 'all') {
        data = await this.$api.listInquiryProject(searchCondition)
      }
      if (this.isActive === 'my') {
        data = await this.$api.listInquiryOwner(searchCondition)
      } else if (this.isActive === 'myGroup') {
        data = await this.$api.ownerGroup(searchCondition)
      }
      list = data.list
      page_info = data.page_info

      let convertData = ['is_assign', 'is_platform_pay', 'is_send_back', 'is_cart', 'is_auth_price']
      list.forEach((item) => {
        convertData.forEach((i) => {
          if (i === 'is_assign' || i === 'is_send_back') {
            item[i] = item[i] === 1 ? '是' : '否'
          } else item[i] = item[i] === 2 ? '是' : '否'
        })
      })
      if (type === 'preview') {
        this.previewData = list[0]

        console.log(this.previewData)
        this.previewPagination = page_info
        this.loading = false
        this.previewData.product_num === 1 ? this.dialogVisible = true : this.brandVisible = true
      } else {
        this.tableData = list
        this.tablePagination = page_info
      }
    }
  },
  mounted() {
    this.getAllData()
  }
}
</script>

<style scoped>

</style>
